<template>
  <div class="pd-20">
    <a-page-header class="pd-0">
      <div slot="title">
        <h1>
          <i class="fas fa-users gradient"></i>
          Clientes
        </h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10 mr-20"
            src="@/assets/images/dashboard/excel.png"
            alt="img"
          />
        </downloadExcel>

        <a-button type="primary" ghost @click="openCreateCustomer = true">
          <a-icon type="user-add" /> NOVO CLIENTE
        </a-button>
      </div>
    </a-page-header>

    <aCollapse
      class="travel-filters expandable mt-10 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <aCollapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <aRow class="mt-0 mb-5" :gutter="20">
          <aCol :span="6">
            <div class="travel-input">
              <label class="filled">Nome do cliente</label>
              <a-input
                placeholder="Escreva o nome, nome fantasia ou razão social"
                v-model="customers.filters.customerName"
                @change="getCustomers()"
              />
            </div>
          </aCol>
          <aCol v-if="$root.isAdmin()" :span="5">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="customers.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getCustomers()"
              >
                <a-select-option
                  v-for="(item, index) of customers.filters.users.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol v-if="$root.isAdmin()" :span="5">
            <div class="travel-input">
              <label class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                optionFilterProp="txt"
                mode="multiple"
                v-model="customers.filters.status.selected"
                show-search
                style="width: 100%"
                @change="getCustomers()"
              >
                <a-select-option
                  v-for="(item, index) of customers.filters.status.list"
                  :key="index"
                  :value="item.value"
                  :txt="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </aCol>

          <aCol :span="5">
            <a-checkbox
              v-model="customers.filters.completeCustomer"
              style="font-size: 11px; font-weight: 500; position: relative"
              @change="getCustomers()"
            >
              Cliente com cadastro completo
            </a-checkbox>
          </aCol>
        </aRow>
      </aCollapse-panel>
    </aCollapse>

    <aTable
      class="travel-table"
      :columns="customersTableCollumns"
      :data-source="customers.list"
      :loading="customers.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="name" slot-scope="record">
        <div class="upper dotted-phrase" v-html="formatClient(record)" />
      </div>

      <div slot="email" class="upper" slot-scope="record">
        {{ record }}
      </div>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }}
            {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              :size="30"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </aTable>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="customers.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="customers.pagination.perPage"
        :total="customers.pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>

    <a-drawer
      title="NOVO CLIENTE"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCustomer"
      @close="openCreateCustomer = false"
    >
      <a-form @submit.prevent="submitCustomer" :form="customerForm">
        <aRow
          class="box box-contractor"
          :gutter="[20, 0]"
          style="margin-top: -20px"
        >
          <CustomerForm
            v-if="openCreateCustomer"
            :customer="{}"
            :customerForm="customerForm"
            :requiredFields="{
              cep: false,
              location_city: false,
              location_neighborhood: false,
              location_number: false,
              location_state: false,
              location_street: false,
            }"
          />
          <aCol class="a-center mt-20" :span="24">
            <a-button
              icon="user"
              type="primary"
              :loading="loadingSubmitCustomer"
              html-type="submit"
            >
              Cadastrar cliente
            </a-button>
          </aCol>
        </aRow>
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
import CustomerForm from "@/components/customers/forms/CustomerForm.vue";
import customerMixins from "@/components/customers/mixins/customerMixins";

export default {
  components: { CustomerForm },
  mixins: [customerMixins],
  data() {
    return {
      customerForm: this.$form.createForm(this),
      openCreateCustomer: false,
      loadingSubmitCustomer: false,
      excelFile: {
        header: "Clientes",
        fileName: "Clientes.xls",
        collumns: {
          ID: "id",
          "Nome Cliente/Empresa": {
            callback: (client) => {
              let name = "";

              if (client.person_type == "Pessoa Física") {
                name = `${client.first_name} ${client.last_name}`;
              }

              if (client.person_type == "Pessoa Jurídica") {
                name = `${client.trading_name}`;
              }

              return name;
            },
          },
          "E-mail": "email",
          Celular: "mobile_phone",
          Telefone: "phone",
          Estado: "location_state",
          "Tipo de Pessoa": "person_type",
          "Cadastrado em": "created",
        },
        data: [],
        footer: [],
      },
      pageSizeOptions: ["10", "30", "50", "100", "200", "500", "1000", "99999"],
    };
  },
  beforeMount() {
    this.customers.pagination.perPage = 30;
    this.customers.filters.status.selected = ["Ativo"];
    this.getCustomers();

    this.$http
      .get("/user/list?page=1&per_page=100&user_id=1")
      .then(({ data }) => {
        this.customers.filters.users.list = data.data.map((user) => {
          return {
            label: `${user.id} - ${user.first_name} ${user.last_name}`,
            value: user.id,
          };
        });
      });
  },
  methods: {
    formatClient(client) {
      let name = "";

      if (client.person_type == "Pessoa Física") {
        name = `${client.first_name} ${client.last_name}`;
      }

      if (client.person_type == "Pessoa Jurídica") {
        name = `${client.trading_name}`;
      }

      return name;
    },

    listAirlines() {
      this.openCreateCustomer = false;
      this.getCustomers();
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    changePage(current) {
      this.customers.pagination.page = current;
      this.getCustomers();
    },
    changePageSize(current, pageSize) {
      this.customers.pagination.page = current;
      this.customers.pagination.perPage = pageSize;
      this.getCustomers();
    },
    submitCustomer() {
      this.customerForm.validateFields(async (err, customer) => {
        console.log(err, customer);
        if (!err) {
          this.loadingSubmitCustomer = true;
          customer.action = "create-customer";
          customer.avatar = "";
          customer.status = 1;
          customer.user_id = this.$store.state.userData.id;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.openCreateCustomer = false;
              this.getCustomers();
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
  },
};
</script>
